const REGEX_PATTERNS = {
  latlng: /^(-?\d+\.\d+),(-?\d+\.\d+),(-?\d+)+o$/,
}

type IsLatLngQueryString = (path: string | undefined) => boolean

type CreateGeolocationFeature = (
  path: string | null,
  properties: object
) => object | null

export interface IExtendGeolocationPosition extends GeolocationCoordinates {
  timestamp: number
  coordinates: [number, number] | null
}

export type GetCurrentGeolocation = (
  options?: PositionOptions
) => Promise<IExtendGeolocationPosition | GeolocationPositionError>

export const isLatLngQueryString: IsLatLngQueryString = (path) =>
  REGEX_PATTERNS["latlng"].test(path)

export const createGeolocationFeature: CreateGeolocationFeature = (
  path,
  properties = {}
) => {
  try {
    const [id, latitude, longtitude, ordinal] = path.match(
      REGEX_PATTERNS["latlng"]
    )
    return {
      type: "Feature",
      feature_type: "geolocation",
      id,
      geometry: {
        type: "Point",
        coordinates: [parseFloat(latitude), parseFloat(longtitude)],
      },
      properties: {
        ordinal: parseInt(ordinal),
        userLocationParam: path,
        queryString: `?user-location=${path}`,
        ...properties,
      },
    }
  } catch (error) {
    return null
  }
}

export const getCurrentGeolocation: GetCurrentGeolocation = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords, timestamp }) => {
        const { latitude, longitude } = coords
        const transformedResult = {
          accuracy: coords.accuracy,
          altitude: coords.altitude,
          altitudeAccuracy: coords.altitudeAccuracy,
          heading: coords.heading,
          latitude: coords.latitude,
          longitude: coords.longitude,
          speed: coords.speed,
          timestamp,
          coordinates: longitude && latitude ? [longitude, latitude] : null,
        } as IExtendGeolocationPosition
        resolve(transformedResult)
      },
      (error) => reject(error),
      {
        enableHighAccuracy: true,
        ...options,
      }
    )
  })
}
