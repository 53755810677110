import config from "config"
import { alpha } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const mixins = {
  vKioskHeader: {
    height: 285, // kiosk header
  },
  vKioskFooter: {
    height: 300, // kiosk footer
  },
  kioskNavigation: {
    height: 240,
  },
  bottomNavigation: {
    height: 80,
  },
  mobileHeader: {
    height: 70,
  },
  mobileDirectionSearch: {
    width: 380,
  },
}

export const themeOptionFactory = (theme) => ({
  mixins,
  assets: {
    /**
     * The icon configuration format for pages with no data.
     * Example:
     * promotions: {
     *   "icon_comp": "_COMPONENT_NAME_", // optional
     *   "icon_path": "_SVG_PATH_", // optional
     *   "icon_img": "_IMG_SRC_", // optional
     * }
     */
    empty: {
      promotions: {},
      events: {},
      dining: {},
      newShops: {},
      directory: {},
      touristPrivilege: {},
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          width: "fit-content",
          height: "20px",
        },
        switchBase: {
          padding: 0,
          margin: theme.spacing(1.4),
          marginTop: theme.spacing(0.2),
          transitionDuration: theme.transitions.duration.standard,
          "&.MuiButtonBase-root:hover": {
            backgroundColor: "transparent",
          },
          "&.Mui-checked": {
            transform: "translateX(7px)",
            color: theme.palette.primary.contrastText,
            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: theme.palette.primary.main,
            border: `6px solid ${theme.palette.primary.contrastText}`,
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.grey[100],
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
          },
        },
        thumb: {
          boxSizing: "border-box",
          width: "16px",
          height: "16px",
          marginLeft: theme.spacing(-1),
        },
        track: {
          width: "30px",
          height: "20px",
          borderRadius: "10px",
          backgroundColor: theme.palette.grey[300],
          opacity: 1,
          transition: theme.transitions.create(["background-color"], {
            duration: 500,
          }),
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          width: "100%",
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[10],
          height: mixins.bottomNavigation?.height,
          padding: theme.spacing(0, 6.75),
          gap: 10,
          [theme.breakpoints.up("sm")]: {
            width: "auto",
            gap: 40,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 1, 1),
          "& .MuiSvgIcon-root": {
            marginBottom: 8,
            width: 20,
            height: 20,
          },
          "&.Mui-selected": {
            "&:after": {
              content: "''",
              display: "block",
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: "4px",
              width: "100%",
              borderRadius: 9999,
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
        label: {
          textAlign: "center",
          fontSize: "0.875rem",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[500],
          "&.Mui-disabled": {
            backgroundColor: "#ECECEC",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
      styleOverrides: {
        select: {
          padding: theme.spacing(1.5, 4, 1.5, 2),
          borderRadius: "8px",
          fontSize: 12,
        },
        icon: {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.75rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          fontSize: "0.75rem",
        },
      },
    },
    AppSidebar: {
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(3),
          backgroundColor: theme.palette.grey[50],
          width: "100%",
          [theme.breakpoints.up("sm")]: {
            maxWidth: "428px",
          },
        },
        header: {},
        body: {
          flexGrow: 1,
          overflowX: "hidden",
          overflowY: "auto",
        },
        footer: {},
        footerLogo: {},
        flagIcon: {
          borderRadius: "2px",
          aspectRatio: "1.5/1",
          width: "20px",
          height: "auto",
        },
        closeButton: {
          position: "absolute",
          right: "32px",
          top: "24px",
          zIndex: 1,
          "& .MuiSvgIcon-root": {
            width: "14px",
            height: "14px",
            fontSize: "14px",
            fill: theme.palette.text.primary,
          },
        },
        navList: {},
        navDivider: {
          borderBottomWidth: "1px",
          margin: theme.spacing(0, 3),
          "&+ .MuiDivider-root": {
            display: "none",
          },
        },
        navListItem: {
          padding: theme.spacing(1.375, 3),
          "& .MuiTypography-root": {
            fontWeight: 500,
            fontSize: "1rem",
          },
          "& .MuiListItemIcon-root": {
            color: theme.palette.primary.main,
            minWidth: 40,
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.125rem",
          },
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2, 3),
            "& .MuiTypography-root": {
              fontSize: "1.125rem",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "1.25rem",
            },
          },
        },
        subList: {},
        subDivider: {
          marginLeft: theme.spacing(8),
          borderBottomWidth: "1px",
        },
        subListItem: {
          paddingLeft: theme.spacing(8),
          color: "inherit",
          "& .MuiTypography-root": {
            fontWeight: 500,
            fontSize: "0.875rem",
          },
          "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall": {
            fontSize: "0.5rem",
          },
          "& .MuiListItemIcon-root": {
            color: theme.palette.primary.main,
            minWidth: "30px",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1rem",
          },
          "&:last-of-type": {
            borderBottom: "none",
          },
          "&:last-of-type + .MuiDivider-root": {
            display: "none",
          },
        },
        languageSelectorWrapper: {
          "& .MuiList-root .MuiDivider-root": {
            display: "none",
          },
        },
      },
    },
    AppDining: {
      styleOverrides: {
        root: {},
        filterSection: {
          margin: theme.spacing(0, -3),
        },
        filterContainer: {
          paddingLeft: 0,
          paddingBottom: 3,
        },
        filterButton: {
          gap: theme.spacing(1),
          padding: 0,
          "& .MuiToggleButton-root": {
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: theme.spacing(3),
          },
          whiteSpace: "nowrap",
          "& .MuiSvgIcon-root": {
            marginRight: theme.spacing(1),
          },
        },
        gridContainer: {
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(175px, 1fr))",
          justifyItems: "center",
          gap: "8px",
        },
        diningImageCard: {
          display: "block",
          overflow: "hidden",
          aspectRatio: "1 / 1",
          width: "100%",
          height: "100%",
          "& .MuiCardActionArea-root": {
            width: "100%",
            height: "100%",
          },
          "& .MuiCardMedia-img": {
            width: "100%",
            height: "100%",
          },
          "& .MuiCardHeader-root": {
            paddingBottom: theme.spacing(1),
          },
          "& .MuiCardHeader-root .MuiCardHeader-title": {
            lineHeight: 1.3,
          },
          "& .MuiCardHeader-content": {
            overflow: "hidden",
          },
          "& .MuiCardHeader-title": {
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            marginBottom: theme.spacing(0.1875),
          },
          "& .MuiCardHeader-subheader": {
            "& .booth-name-text": {
              display: "none",
            },
          },
        },
        diningAvatar: {
          width: "50px",
          height: "50px",
        },
        diningAvatarFallBack: {},
        emptyDataContainer: {
          height: `calc(100vh - ${mixins.mobileHeader?.height}px - ${
            mixins.bottomNavigation?.height + 30
          }px - 72px)`,
          // Prevent content page overlap with footer shadow by adding a bottom space (30px)
          //72 px is container padding
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        venueSelector: {
          position: "fixed",
          bottom: mixins.bottomNavigation?.height + 45,
          right: "24px",
          zIndex: 1, // Prevents restaurants' info from appearing on top
        },
      },
    },
    AppCategoryFilterButton: {
      styleOverrides: {
        root: {
          padding: 0,
          width: 70,
          flexDirection: "column",
          justifyContent: "start",
          gap: theme.spacing(0.75),
          border: "none",
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.grey[500],
          "&.Mui-selected": {
            "& .MuiSvgIcon-root": {
              color: theme.palette.primary.main,
            },
            "& .MuiTypography-root": {
              color: theme.palette.primary.main,
            },
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "unset",
          },
        },
        label: {
          fontSize: "0.625rem",
          textAlign: "center",
          lineHeight: 1.1,
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightMedium,
        },
        icon: {
          padding: theme.spacing(1.25),
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          border: `1px solid rgba(203, 203, 203, 0.5)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            width: "30px",
            height: "30px",
          },
        },
      },
    },
    AppCategoryFilterChip: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.5),
          borderRadius: "16px",
          boxShadow: "none",
          background: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[200]}`,
          whiteSpace: "nowrap",
          height: "auto",
          color: theme.palette.text.primary,
          fontWeight: 400,
          "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            borderRadius: "16px",
          },
          "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            border: `1px solid ${theme.palette.grey[200]}`,
            borderRadius: "16px",
          },
          "&.Mui-selected": {
            "& svg": {
              fill: theme.palette.primary.contrastText,
            },
          },
          ":hover": {
            background: theme.palette.common.white,
          },
        },
        label: {
          fontSize: "0.8125rem",
          padding: theme.spacing(0, 1.5),
          letterSpacing: "0.00938em",
        },
        icon: {
          "& svg": {
            width: 16,
            height: 16,
            fill: theme.palette.primary.main,
            marginLeft: theme.spacing(0.5),
            marginRight: "-6px",
          },
        },
      },
    },
    AppCategoryFilterBar: {
      styleOverrides: {
        root: {},
        filterButton: {},
      },
    },
    WelcomePanel: {
      defaultProps: {
        showNavigation: false,
        showQuickSearch: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[50],
        },
        panelContainer: {
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
        },
        line: {
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "3px",
          border: "none",
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.common.white} 100%)`,
        },
        content: {
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(6.75, 3, 2),
          [theme.breakpoints.up("sm")]: {
            width: 460,
          },
        },
        logoContainer: {
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          marginBottom: theme.spacing(3),
          "& img": {
            maxWidth: 130,
          },
        },
        searchBarContainer: {
          marginBottom: theme.spacing(2),
          width: "100%",
        },
        navItem: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          color: "white",
          flexBasis: 0,
          fontSize: theme.typography.subTitle.fontSize,
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: "19px",
          flexGrow: "1",
          textAlign: "center",
          "& .MuiButton-startIcon": {
            margin: theme.spacing(0, 0, 1.5),
            width: 20,
            height: 20,
          },
        },
        horizontalMenuContainer: {
          padding: 0,
          "& .MuiToggleButtonGroup-root": {
            "& .MuiToggleButtonGroup-grouped:first-of-type": {
              marginLeft: 0,
            },
          },
        },
      },
    },
    AppHeader: {
      styleOverrides: {
        root: {
          position: "relative",
          backgroundColor: theme.palette.grey[50],
          boxShadow: "none",
          width: "100%",
          color: theme.palette.text.primary,
          fontWeight: 600,
          textAlign: "center",
        },
        toolbar: {
          minHeight: mixins.mobileHeader.height,
          justifyContent: "space-between",
          padding: theme.spacing(0, 3.25),
          width: `100%`,
          [theme.breakpoints.up("md")]: {
            margin: `0 auto`,
            maxWidth: 900,
          },
        },
        logoContainer: {
          display: "none",
        },
        titleContainer: {
          width: "100%",
        },
        clockContainer: {
          display: "none",
        },
        backButtonContainer: {
          position: "absolute",
        },
        line: {
          display: "none",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          border: "none",
        },
      },
    },
    AppPageLayout: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          position: "relative",
          paddingBottom: `${(mixins.bottomNavigation?.height || 75) + 30}px`,
          minHeight: "calc(100% - 75px)",
          backgroundColor: theme.palette.background.default,
        },
        headerBackIconContainer: {
          marginRight: 2,
          backgroundColor: "#fff",
          borderRadius: "50%",
          height: "40px",
          width: "40px",
          "& button": {
            height: "100%",
            width: "100%",
            margin: "auto",
          },
        },
        navigationContainer: {
          position: "fixed",
          zIndex: theme.zIndex.appBar,
          bottom: 0,
          left: 0,
          right: 0,
        },
        container: {
          padding: theme.spacing(4, 3),
        },
        headerWrapper: {},
      },
    },
    AppClock: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          textTransform: "uppercase",
          whiteSpace: "pre-wrap",
          lineHeight: 1.2,
        },
        timeWrapper: {
          textAlign: "left",
          fontSize: "50px",
        },
        dateText: {
          textAlign: "right",
          fontSize: "30px",
        },
        timeLabel: {
          fontWeight: 300,
          fontSize: "16px",
        },
        timeText: {},
        timeUnitText: {
          fontWeight: 300,
          fontSize: "16px",
          marginBottom: theme.spacing(2),
        },
      },
    },
    AppMobileNavigation: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
        },
        menuButton: {},
        moreButton: {
          textAlign: "center",
        },
      },
    },
    AppKioskNavigation: {
      styleOverrides: {
        root: {
          position: "fixed",
          zIndex: theme.zIndex.appBar,
          bottom: mixins.vKioskFooter.height,
          left: 0,
          right: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: theme.spacing(0, 6.25),
        },
        menuButton: {
          width: 138,
          "& .MuiSvgIcon-root": {
            marginBottom: theme.spacing(2),
            width: 32,
            height: 32,
          },
        },
        navigationContainer: {
          width: "auto",
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[6],
          marginBottom: theme.spacing(3),
          maxWidth: 979,
          height: 127,
          borderRadius: "20px",
          padding: 0,
          [theme.breakpoints.up("sm")]: {
            maxWidth: "inherit",
            borderRadius: "20px",
            gap: 0,
          },
        },
        toolbarContainer: {
          display: "flex",
          flexDirection: "row",
          marginBottom: theme.spacing(3),
          width: "100%",
          boxShadow: "inherit",
          gap: theme.spacing(2),
        },
        searchBarContainer: {
          width: "100%",
          height: 48,
        },
        actionContainer: {
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1.5),
        },
        actionButton: {
          minWidth: 128,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
          "& .MuiSvgIcon-root": {
            marginRight: 0,
            width: 32,
            height: 32,
          },
        },
      },
    },
    AppNavigationLink: {
      styleOverrides: {
        root: {},
        backSide: {
          display: "none",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 0,
        },
      },
    },
    AppMapLayout: {
      styleOverrides: {
        mapContainer: {
          width: "100%",
          position: "relative",
        },
        outletWrapper: {
          position: "relative",
        },
      },
    },
    AppFooter: {
      styleOverrides: {
        root: {},
      },
    },
    AppTopBar: {
      styleOverrides: {
        root: {
          padding: theme.spacing(4, 2),
          top: 0,
          position: "absolute",
          width: "fit-content",
          left: 0,
          right: 0,
          zIndex: theme.zIndex.modal,
        },
        ordinalSelector: {
          position: "static",
          "&.MuiList-root": {
            borderRadius: 9999,
            width: "48px",
            height: "48px",
            padding: theme.spacing(0.4),
            backgroundColor: theme.palette.background.paper,
          },
          "& .MuiListItemButton-root": {
            height: "100%",
          },
        },
        backButton: {
          color: theme.palette.primary.main,
        },
      },
    },
    DirectionSearchInput: {
      styleOverrides: {
        directionSearchWrapper: {
          boxShadow: theme.shadows[1],
          background: theme.palette.common.white,
          padding: theme.spacing(3, 2),
          borderRadius: "10px",
          display: "flex",
        },
        backIconWrapper: {
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          marginRight: theme.spacing(1),
        },
        directionWrapper: {
          flexGrow: 1,
          display: "flex",
        },
        switchIconWrapper: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: theme.spacing(1),
        },
        directionInputWrapper: {
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        },
        directionIconWrapper: {
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          marginRight: theme.spacing(1.5),
        },
        directionInput: {
          fontSize: "0.875rem",
          fontWeight: 500,
          flexGrow: 1,
          padding: theme.spacing(0, 0.5),
          "& .MuiInputBase-input": {
            padding: 0,
          },
        },
        iconButton: {
          color: theme.palette.text.primary,
          fontSize: "24px",
          margin: "-5px",
        },
        backIconButton: {},
        switchIconButton: {},
        connectorIcon: {},
        originIcon: {},
        destinationIcon: {},
        divider: {
          margin: theme.spacing(1, 0),
          borderBottomWidth: "1px",
        },
        clearIconButton: {
          color: theme.palette.text.primary,
          fontSize: "24px",
          margin: "-5px",
          "& .MuiSvgIcon-root": {
            height: "8px",
            width: "8px",
          },
        },
      },
    },
    AppDirectionSearch: {
      styleOverrides: {
        headerWrapper: {
          padding: theme.spacing(3),
          [theme.breakpoints.up("sm")]: {
            maxWidth: mixins.mobileDirectionSearch.width,
          },
        },
        paper: {
          borderRadius: "10px",
          padding: theme.spacing(0.75, 1.25),
          display: "flex",
          alignItems: "center",
        },
        formControlLabel: {
          margin: 0,
          "& .MuiFormControlLabel-label": {
            fontWeight: 500,
            marginLeft: theme.spacing(2),
          },
        },
      },
    },
    AppMap: {
      styleOverrides: {
        toolbarContainer: {},
        quickLinkContainer: {
          overflowX: "scroll",
          padding: 0,
          display: "flex",
          maxWidth: "100%",
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
        },
        navigationContainer: {
          position: "fixed",
          zIndex: theme.zIndex.appBar,
          bottom: 0,
          left: 0,
          right: 0,
        },
        searchBarContainer: {
          padding: theme.spacing(3, 3, 1, 3),
          [theme.breakpoints.up("md")]: {
            maxWidth: "350px",
          },
        },
        indoorMapContainer: {
          position: "absolute",
          zIndex: 0,
          width: "100%",
          height: "100%",
          "& > #map": {
            width: "100%",
            height: "100%",
          },
          "& > * > .maptalks-wrapper": {
            transition: "all 500ms ease-out",
            // * Fixing Map transition not working when navigate from Directory to Place Detail.
            WebkitTransition: "-webkit-transform 500ms ease-out",
          },
          "&.bottom-half": {
            overflow: "hidden",
            "& .maptalks-wrapper": {
              transform: `translate(0, 24%)`,
            },
          },
          "&.hidden": {
            opacity: 0,
            visibility: "hidden",
          },
        },
        ordinalSelectMenu: {
          position: "fixed",
          bottom: "125px",
          right: "24px",
        },
        ordinalSelector: {
          display: "none",
        },
        venueSelector: {
          position: "fixed",
          bottom: mixins.bottomNavigation?.height + 45,
          left: "24px",
        },
      },
    },
    AppNavigationStep: {
      styleOverrides: {
        wrapper: {
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        },
        container: {
          position: "fixed",
          bottom: "25px",
          zIndex: theme.zIndex.modal,
          display: "flex",
          right: 0,
          left: 0,
          background: theme.palette.background.paper,
          borderRadius: `10px`,
          padding: theme.spacing(2, 3),
          flexDirection: "column",
          height: 170,
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          [theme.breakpoints.up("sm")]: {
            maxWidth: 565,
            margin: "auto",
          },
        },
        kioskBackButton: {
          display: "none",
        },
        title: {
          fontSize: "inherit",
          fontWeight: "bold",
        },
        primaryText: {
          color: theme.palette.text.primary,
          textAlign: "center",
          fontWeight: 600,
          lineHeight: 1.5,
        },
        highlightedText: {
          color: theme.palette.primary.main,
          textAlign: "inherit",
          fontWeight: "inherit",
          lineHeight: "inherit",
          fontSize: "inherit",
        },
        secondaryText: {
          color: theme.palette.grey[500],
          textAlign: "center",
          lineHeight: 1.5,
          fontWeight: 600,
        },
        locationText: {
          display: "none",
          color: theme.palette.text.primary,
          fontSize: 24,
          lineHeight: "33px",
          fontWeight: 700,
          textTransform: "uppercase",
        },
        originIcon: {
          display: "none",
          fontSize: "3.5rem",
        },
        destinationIcon: {
          display: "none",
          fontSize: "3.5rem",
          fill: theme.palette.primary.main,
        },
        headerWrapper: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          color: theme.palette.common.black,
          fontSize: theme.typography.caption.fontSize,
          "& .MuiIconButton-root": {
            fontSize: "inherit",
          },
        },
        stepTextContainer: {
          height: "40px",
          display: "flex",
          alignItems: "center",
        },
        overviewWrapper: {
          justifyContent: "center",
          flexDirection: "row",
          gap: theme.spacing(1),
        },
        navButtonWrapper: {
          display: "none",
          flexDirection: "row",
          justifyContent: "center",
          gap: theme.spacing(4),
        },
        iconNavButton: {
          display: "block",
          padding: 0,
        },
        exitButtonWrapper: {
          position: "absolute",
          top: 0,
          right: 0,
          marginTop: theme.spacing(-6.5),
        },
        exitButton: {
          backgroundColor: theme.palette.background.default,
          border: `1px solid #ececec`,
          color: theme.palette.text.primary,
          fontWeight: 600,
          "& .MuiSvgIcon-root": {
            fill: theme.palette.primary.main,
          },
          "&:hover": {
            backgroundColor: theme.palette.grey[200],
          },
        },
        borderLinearProgressBar: {
          display: "none",
        },
        stepProgressBar: {
          display: "block",
        },
      },
    },
    AppFeatureDetail: {
      styleOverrides: {
        root: {
          position: "relative",
          overflow: "initial",
          boxShadow: "none",
        },
        section: {
          margin: theme.spacing(3, 0),
          "&:first-of-type": {
            marginTop: 0,
          },
        },
        galleryImage: {
          objectFit: "cover",
          height: 130,
          width: 182,
          display: "block",
          boxShadow: theme.shadows[2],
          borderRadius: "6px",
          cursor: "pointer",
        },
        divider: {
          borderBottom: `8px solid #F7F7FF`,
          marginLeft: theme.spacing(-3),
          marginRight: theme.spacing(-3),
        },
        descriptionText: {
          color: theme.palette.text.secondary,
        },
        contactButton: {
          textTransform: "lowercase",
          display: "flex",
          marginBottom: theme.spacing(2.5),
          color: theme.palette.text.primary,
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        contactWrapper: {
          marginBottom: theme.spacing(1.25),
        },
        sectionTitle: {
          fontSize: theme.typography.subtitle1.fontSize,
          fontWeight: theme.typography.fontWeightBold,
          marginBottom: theme.spacing(2),
          color: theme.palette.text.primary,
        },
      },
    },
    AppImageModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(2),
        },
        imageWrapper: {
          position: "relative",
          outline: "none",
          "& img": {
            borderRadius: "8px",
            width: "100%",
          },
          [theme.breakpoints.up("sm")]: {
            width: "400px",
          },
        },
        closeButton: {
          color: theme.palette.common.white,
          position: "absolute",
          top: theme.spacing(-4.5),
          right: theme.spacing(-1),
          [theme.breakpoints.up("sm")]: {
            right: theme.spacing(-2),
          },
        },
      },
    },
    AppYouAreHereMarker: {
      styleOverrides: {
        root: {
          "& .primary": {
            fill: theme.palette.primary.main,
          },
          "& .primary-light": {
            fill: theme.palette.secondary.main,
          },
          "& .primary-border": {
            stroke: theme.palette.primary.main,
          },
          "& .text-primary": {
            fill: theme.palette.text.primary,
          },
          "& .secondary": {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
    DirectionStep: {
      styleOverrides: {
        originIcon: {
          fill: theme.palette.primary.main,
          width: 37,
          height: 37,
        },
        destinationIcon: {
          fill: theme.palette.error.main,
          width: 28,
          height: 47,
        },
        highlightedText: {
          color: theme.palette.primary.main,
          textAlign: "inherit",
          fontWeight: "inherit",
          lineHeight: "inherit",
          fontSize: "inherit",
        },
      },
    },
    AppDirectionOverview: {
      defaultProps: {
        drawer: { drawerBleeding: config("device") === "mobile" ? 190 : 0 },
      },
      styleOverrides: {
        root: {},
        primaryText: {
          marginBottom: theme.spacing(1.5),
        },
        secondaryText: {
          fontWeight: 700,
        },
        drawer: {},
        bleedWrapper: {
          padding: theme.spacing(5.5, 2.75, 2.75),
          "button, a": { pointerEvents: "all" },
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2.5),
        },
        stepper: {
          "& .MuiStep-vertical": {
            ":not(:last-of-type)": {
              "& .MuiStepLabel-labelContainer": {
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
            },
            "& .MuiStepLabel-labelContainer": {
              padding: theme.spacing(2.5, 0),
            },
          },
        },
        stepOverviewContainer: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          "button, a": { pointerEvents: "all" },
        },
        directionSummaryContainer: {
          [theme.breakpoints.down("sm")]: {
            height: "70px",
          },
        },
      },
    },
    DirectionCardActions: {
      styleOverrides: {
        root: {
          height: "auto",
          padding: 0,
          display: "flex",
          flexDirection: "row",
          gap: theme.spacing(1),
        },
        actionButton: {
          [theme.breakpoints.down("sm")]: {
            fontWeight: 700,
            "&.MuiButton-outlined": {
              border: `1px solid ${theme.palette.grey[400]}`,
            },
            "& .MuiButton-startIcon": {
              marginRight: theme.spacing(1.25),
            },
          },
        },
      },
    },
    HomePage: {
      styleOverrides: {
        root: {},
        contentContainer: {
          display: "flex",
          flexDirection: "column",
        },
        welcomePanelWrapper: {},
        featureCarouselWrapper: {
          padding: theme.spacing(3, 3, 12, 3),
        },
        textCard: {
          borderRadius: "15px",
          height: 136,
          display: "flex",
          alignItems: "center",
          p: theme.spacing(1),
          justifyContent: "center",
          flex: 1,
        },
        linkCardContainer: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 1.25,
          "&>*": {
            flex: 1,
          },
        },
        defaultCardIcon: {
          color: theme.palette.primary.main,
          display: "flex",
          justifyContent: "center",
        },
        defaultCardPrimaryText: {
          textAlign: "center",
          fontSize: "14px",
          lineHeight: 1.4,
        },
        defaultCardSecondaryText: {
          color: theme.palette.text.secondary,
          textAlign: "center",
          fontSize: "12px",
          lineHeight: 1.4,
        },
        navigationWrapper: {
          position: "fixed",
          zIndex: theme.zIndex.appBar,
          bottom: 0,
          left: 0,
          right: 0,
        },
        carousel: {},
        carouselItem: {},
      },
    },
    FullScreenStart: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          zIndex: -1,
          background: `linear-gradient(0deg, ${alpha(
            theme.palette.common.black,
            0.4
          )} 0%, ${alpha(theme.palette.common.black, 0.4)} 100%)`,
        },
        actionButtonWrapper: {
          position: "relative",
          display: "inline-block",
          "&:after, :before": {
            opacity: 0,
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            height: "100%",
            width: "100%",
            border: `5px solid ${alpha(theme.palette.common.white, 0.4)}`,
            borderRadius: 9999,
            zIndex: -1,
          },
          "&:after": {
            animation: `ripple 3s infinite ${theme.transitions.easing.easeInOut}`,
          },
          "&:before": {
            animation: `ripple 3s infinite ${theme.transitions.easing.easeInOut} 0.5s`,
          },
        },
        actionButton: {
          position: "relative",
          fontSize: "1rem",
          textTransform: "uppercase",
          color: theme.palette.text.secondary,
          fontWeight: 700,
          borderRadius: 999,
          backgroundColor: theme.palette.common.white,
          transition: theme.transitions.create("all"),
          boxShadow: theme.shadows[10],
          padding: theme.spacing(1.25, 3),
          "&:hover": {
            boxShadow: theme.shadows[20],
            backgroundColor: theme.palette.common.white,
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
          "& .MuiButton-startIcon>svg": {
            fontSize: "1.5rem",
            color: theme.palette.primary.main,
          },
          [theme.breakpoints.up("md")]: {
            transform: "scale(1.2)",
          },
        },
      },
    },
    DirectoryPage: {
      styleOverrides: {
        root: {
          margin: theme.spacing(0, -3),
          "& .MuiList-root": {},
        },
        filterSection: {
          position: "sticky",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          top: 0,
          zIndex: 1,
          marginTop: theme.spacing(-4),
          paddingTop: theme.spacing(3),
          background: theme.palette.background.paper,
        },
        categoryFilterBar: {
          gap: theme.spacing(2),
          padding: 0,
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: theme.spacing(3),
          },
        },
        subCategoryFilterBar: {
          gap: theme.spacing(2),
          padding: 0,
          "& .MuiToggleButton-root": {
            borderRadius: "9999px",
            padding: theme.spacing(1.5),
            height: "unset",
            "& .MuiSvgIcon-root": {
              display: "none",
            },
          },
          "& .MuiButtonBase-root.MuiToggleButtonGroup-grouped:first-of-type": {
            borderRadius: "9999px",
            marginLeft: theme.spacing(3),
          },
          "& .MuiButtonBase-root.MuiToggleButtonGroup-grouped:last-of-type": {
            marginRight: theme.spacing(3),
          },
          "& .MuiButtonBase-root.MuiToggleButtonGroup-grouped:not(:first-of-type)":
            {
              borderRadius: "9999px",
            },
        },
        scrollPane: {
          padding: 0,
          "& .MuiToggleButtonGroup-root": {
            gap: theme.spacing(1),
          },
          "& .MuiDivider-root": {
            borderBottomWidth: 8,
          },
        },
        buildingWithLevelFilterSection: {
          display: "none",
          gap: theme.spacing(2),
          padding: theme.spacing(0, 3),
          "& .MuiToggleButtonGroup-root": {
            gap: theme.spacing(1),
          },
          "& .MuiDivider-root": {
            borderBottomWidth: 8,
          },
        },
        buildingFilterSelect: {
          flex: "5 0 0",
        },
        levelFilterSelect: {
          flex: "4 0 0",
        },
        selectMenuItem: {
          fontSize: "0.75rem",
        },
        resetButton: {
          color: theme.palette.info.main,
          textDecoration: "underline",
          fontSize: "0.75rem",
        },
        divider: {
          borderBottomWidth: "0.5px",
          background: theme.palette.grey[500],
        },
        list: {
          padding: theme.spacing(0, 1),
          paddingTop: theme.spacing(1),
        },
        emptyDataWrapper: {
          marginTop: theme.spacing(3),
        },
        venueSelector: {
          display: "block",
          position: "fixed",
          bottom: mixins.bottomNavigation?.height + 45,
          right: "24px",
        },
      },
    },
    LevelFilterBar: {
      styleOverrides: {
        root: {
          padding: 0,
          "& .MuiToggleButton-root": {
            padding: theme.spacing(1, 3),
            flexShrink: 0,
          },
          "& .MuiToggleButton-root:first-of-type": {
            marginLeft: theme.spacing(3),
          },
          "& .MuiToggleButton-root:last-of-type": {
            marginRight: theme.spacing(3),
          },
        },
      },
    },
    DirectoryOccupantCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          display: "flex",
          height: "80px",
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          "& .MuiTypography-root": {
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
          },
        },
        occupantLogo: {
          boxShadow: theme.shadows[1],
          minWidth: "80px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .MuiAvatar-root": {
            borderRadius: "0",
            width: "100%",
            height: "100%",
          },
        },
        cardContent: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: theme.spacing(1, 1.4),
          "& .MuiTypography-root": {
            lineHeight: 1.25,
          },
        },
      },
    },
    FeatureSkeletonListItem: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        bottomDivider: {
          height: "1px",
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
        listItemTextPrimary: {
          fontSize: "1rem",
        },
        listItemTextSecondary: {
          fontSize: "0.875rem",
        },
        listItemAvatar: {},
        avatar: {
          width: 55,
          height: 55,
          marginRight: theme.spacing(2.25),
        },
        listItemContent: {
          flex: 2.5,
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(0.5),
        },
      },
    },
    PromotionPage: {
      styleOverrides: {
        root: {},
        carouselContainer: {},
        categoryFilterBarContainer: {},
        cardListContainer: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px",
        },
        carousel: {
          "& .splide__pagination": {
            marginTop: "50px",
          },
          "& .splide__pagination__page": {
            width: "14px",
            height: "14px",
          },
          "& .splide__pagination__page.is-active": {
            backgroundColor: theme.palette.primary.main,
            transition: `width ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, borderRadius ${theme.transitions.duration.standard}s ${theme.transitions.easing.easeInOut}`,
            transform: "none",
            width: 45,
            borderRadius: 30,
          },
        },
        emptyDataContainer: {
          height: `calc(100vh - ${mixins.mobileHeader?.height}px - ${
            mixins.bottomNavigation?.height + 30
          }px - 72px)`,
          // Prevent content page overlap with footer shadow by adding a bottom space (30px)
          //72 px is container padding
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        venueSelector: {
          position: "fixed",
          bottom: mixins.bottomNavigation?.height + 45,
          right: "24px",
        },
        boothText: {
          display: "none",
        },
      },
    },

    FeatureActionContainer: {
      styleOverrides: {
        shortInfoActionWrapper: {
          "button, a": {
            pointerEvents: "all",
          },
          [theme.breakpoints.up("sm")]: {
            paddingBottom: theme.spacing(3),
          },
        },
        navigationButton: {
          padding: theme.spacing(1, 2),
        },
        actionButton: {
          borderColor: theme.palette.grey[600],
          color: theme.palette.grey[600],
          padding: theme.spacing(1, 2),
          "& .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    AppDesktopFeatureDialog: {
      styleOverrides: {
        dialog: {
          height: "100%",
          maxWidth: "50vw",
          left: "unset",
          "& .MuiDialog-container": {
            justifyContent: "flex-end",
          },
        },
        contentContainer: {
          padding: theme.spacing(3),
          background: theme.palette.common.white,
          boxShadow: "none",
          [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(1.25),
          },
        },
      },
    },
    EventPage: {
      styleOverrides: {
        root: {},
        cardListContainer: {
          display: "flex",
          flexDirection: "column",
        },
        currentEventsHeading: {
          margin: theme.spacing(0.25, 0, 3, 0),
        },
        upcomingEventsHeading: {
          margin: theme.spacing(5, 0, 3, 0),
        },
        emptyDataWrapper: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(10),
        },
        imageItem: {
          height: "auto",
          minHeight: "754px",
          width: "100%",
          borderRadius: "14px",
        },
        venueSelector: {
          position: "fixed",
          bottom: mixins.bottomNavigation?.height + 45,
          right: "24px",
        },
      },
    },
    VerticalEventCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: "10px",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          minHeight: 307,
          "&.MuiCardContent-dense": {
            "& .MuiCardContent-root ": { padding: theme.spacing(1.5, 1.25) },
          },
        },
        cardMedia: {
          width: "100%",
          height: 179,
          borderRadius: "none",
          objectFit: "cover",
          position: "relative",
        },
        cardContent: {
          height: 128,
          padding: theme.spacing(1.25, 3),
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&:last-child": {
            paddingBottom: theme.spacing(1.25),
          },
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2.5, 4.5),
          },
        },
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
          fontSize: "0.875rem",
        },
        iconContainer: {
          display: "flex",
          alignItems: "center",
          width: "auto",
          marginRight: theme.spacing(0.5),
          "& .MuiSvgIcon-root": {
            height: 16,
            width: 16,
          },
        },
        cardInfoContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.palette.grey[700],
          fontSize: theme.typography.subTitle.fontSize,
        },
        venueLogoContainer: {
          display: "none",
          "& .MuiCardMedia-root": {
            maxWidth: "120px",
          },
        },
        cardContentStack: {},
        durationContainer: {
          color: theme.palette.primary.contrastText,
          fontSize: theme.typography.subTitle.fontSize,
          backgroundColor: theme.palette.primary.main,
          borderRadius: "80px",
          padding: theme.spacing(1, 1),
          width: "fit-content",
        },
        recommendText: {
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
          left: 0,
          borderRadius: "4px 4px 0px 0px",
          textAlign: "center",
          color: theme.palette.primary.contrastText,
        },
      },
    },
    ImageCard: {
      styleOverrides: {
        root: {},
        chipContainer: {
          position: "absolute",
          padding: "3%",
          right: 0,
          top: 0,
          zIndex: 1,
        },
        chip: {
          backgroundColor: theme.palette.error.main,
          borderRadius: "10px",
          color: theme.palette.error.contrastText,
        },
      },
    },
    PromotionCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: "10px",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          minHeight: 307,
          "&.MuiCardContent-dense": {
            "& .MuiCardContent-root ": { padding: theme.spacing(1.5, 1.25) },
          },
        },
        cardMedia: {
          width: "100%",
          height: 179,
          borderRadius: "none",
          objectFit: "cover",
          position: "relative",
        },
        cardContent: {
          height: 128,
          padding: theme.spacing(1.25, 3),
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&:last-child": {
            paddingBottom: theme.spacing(1.25),
          },
          "&.MuiCardContent-root": {
            padding: theme.spacing(1.25, 1),
          },
          [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2.5, 4.5),
          },
        },
        venueLogoContainer: {
          display: "none",
          "& .MuiCardMedia-root": {
            maxWidth: "120px",
          },
        },
        cardContentStack: {},
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
          fontSize: "0.875rem",
        },
        cardInfoContainer: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.palette.grey[700],
          fontSize: theme.typography.subTitle.fontSize,
        },
        durationContainer: {
          color: theme.palette.primary.contrastText,
          fontSize: theme.typography.subTitle.fontSize,
          backgroundColor: theme.palette.primary.main,
          borderRadius: "80px",
          padding: theme.spacing(1, 1),
          width: "fit-content",
        },
        iconContainer: {
          display: "flex",
          alignItems: "center",
          width: "auto",
          marginRight: theme.spacing(0.5),
          "& .MuiSvgIcon-root": {
            height: 16,
            width: 16,
          },
        },
        recommendText: {
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          bottom: 0,
          left: 0,
          borderRadius: "4px 4px 0px 0px",
          textAlign: "center",
          color: theme.palette.primary.contrastText,
        },
      },
    },
    HighlightFeatureCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          display: "flex",
          flexDirection: "row",
          height: 136,
          padding: theme.spacing(1.5, 1.375),
        },
        cardMedia: {
          width: 74,
          maxHeight: 164,
          borderRadius: "5px",
          objectFit: "cover",
          [theme.breakpoints.up("sm")]: {
            width: 95,
          },
        },
        cardContent: {
          padding: theme.spacing(1.375, 0, 1.375, 3),
          "&:last-child": {
            paddingBottom: theme.spacing(1),
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(1, 1, 1, 3),
            },
          },
        },
        contentWrapper: {
          height: "100%",
          justifyContent: "space-between",
        },
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        eventInfoItem: {
          display: "flex",
          flexDirection: "row",
          color: theme.palette.text.secondary,
          "& .MuiTypography-root": {
            fontSize: "0.875rem",
            fontWeight: 500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          },
        },
        iconWrapper: {
          width: "auto",
          marginRight: theme.spacing(1),
          "& .MuiSvgIcon-root": {
            height: 14,
            color: theme.palette.primary.main,
          },
        },
      },
    },
    ListPromotionItemCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: `${(theme.shape.borderRadius * 2) / 3}px`,
          display: "flex",
          flexDirection: "row",
          height: 104,
          padding: theme.spacing(1, 0.5),
        },
        cardMedia: {
          width: 88,
          maxHeight: 88,
          borderRadius: "5px",
          objectFit: "cover",
          [theme.breakpoints.up("sm")]: {
            width: 95,
          },
        },
        cardContent: {
          padding: theme.spacing(0.5, 1, 0.5, 2),
          "&:last-child": {
            paddingBottom: theme.spacing(0.5),
            [theme.breakpoints.up("sm")]: {
              padding: theme.spacing(0.5, 1, 0.5, 2),
            },
          },
        },
        contentWrapper: {
          height: "100%",
          gap: theme.spacing(2),
          justifyContent: "space-between",
        },
        cardTitle: {
          fontWeight: 600,
          fontSize: "14px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        promotionInfoItem: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.palette.text.secondary,
          "& .MuiTypography-root": {
            fontSize: "0.75rem",
            fontWeight: 400,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          },
        },
        iconWrapper: {
          width: "auto",
          marginRight: theme.spacing(1),
          "& .MuiSvgIcon-root": {
            height: 16,
            color: "inherit",
          },
        },
      },
    },
    ListPrivilegeItemCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: "8px",
          padding: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
          "& .MuiSvgIcon-root": {
            color: theme.palette.grey[500],
            width: 16,
            height: 16,
          },
        },
        label: {
          color: theme.palette.text.secondary,
          fontWeight: 600,
        },
      },
    },
    FeatureSummary: {
      styleOverrides: {
        root: {
          height: "100%",
        },
        title: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
        },
        locationText: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
        },
        container: {
          display: "flex",
          gap: theme.spacing(2),
          boxShadow: "none",
        },
        logoContainer: {
          width: "64px",
          height: "64px",
          backgroundColor: theme.palette.grey[300],
          borderRadius: "7.5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        logoImage: {
          borderRadius: "7.5px",
        },
        categoryIcon: {
          height: "40px",
          width: "40px",
          color: theme.palette.common.white,
        },
        venueLogoContainer: {
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },
        venueLogoImage: {
          height: "32px",
          width: "52px",
          objectFit: "contain",
        },
        infoSection: {},
        infoContainer: {},
        endAdornment: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "80px",
          height: "44px",
          paddingLeft: theme.spacing(2),
          "&:after": {
            content: "''",
            position: "absolute",
            height: "100%",
            width: "1px",
            left: 0,
            backgroundColor: theme.palette.grey[500],
            marginTop: theme.spacing(1.25),
            marginBottom: theme.spacing(1.25),
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "subtitle1" },
          style: {
            lineHeight: 1.4,
          },
        },
      ],
    },
    DirectionStepItem: {
      styleOverrides: {
        root: {
          "& .MuiStepLabel-iconContainer": {
            paddingRight: theme.spacing(2.5),
          },
          "&:first-of-type": {
            "& .MuiListItemText-primary": {
              fontWeight: 700,
            },
          },
          "&:last-of-type": {
            "& .MuiListItemText-primary": {
              fontWeight: 600,
            },
            "& .MuiStepLabel-iconContainer": {
              paddingLeft: theme.spacing(0.5),
              paddingRight: theme.spacing(3),
            },
          },

          "& .MuiStepIcon-root": {
            width: 37,
            height: 37,
            "& .MuiStepIcon-text": {
              fontSize: theme.typography.caption.fontSize,
            },
          },
          "& .MuiStepLabel-vertical": {
            padding: 0,
          },
        },
        listItem: {
          "& .MuiListItemText-primary": {
            whiteSpace: "normal",
            color: theme.palette.text.primary,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.subtitle2.fontWeight,
          },
          "& .MuiListItemText-secondary": {
            fontSize: "0.75rem",
          },
          "& .MuiListItemSecondaryAction-root": {
            marginLeft: "auto",
            position: "static",
            transform: "none",
            color: theme.palette.text.secondary,
            right: 0,
          },
        },
      },
    },
    EmptyData: {
      styleOverrides: {
        root: {
          width: "100%",
          alignItems: "center",
          "& .MuiSvgIcon-root": {
            width: "80px",
            height: "80px",
            color: theme.palette.grey[200],
          },
        },
        secondaryText: {
          color: theme.palette.grey[600],
        },
        iconWrapper: {
          marginBottom: theme.spacing(1),
        },
      },
    },
    NewShopsPage: {
      styleOverrides: {
        emptyDataContainer: {
          height: `calc(100vh - ${mixins.mobileHeader?.height}px - ${
            mixins.bottomNavigation?.height + 30
          }px - 72px)`,
          // Prevent content page overlap with footer shadow by adding a bottom space (30px)
          //72 px is container padding
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },

    OccupantCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[1],
          borderRadius: "10px",
          width: "100%",
        },
        cardTitle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          color: theme.palette.text.primary,
        },
        cardMediaWrapper: {
          position: "relative",
        },
        cardMedia: {
          height: "180px",
        },
        cardContent: {
          padding: theme.spacing(1.5, 2),
          "&:last-child": {
            paddingBottom: theme.spacing(1),
          },
        },
      },
    },
    UserLocationListItem: {
      styleOverrides: {
        icon: {
          background: "transparent",
          color: theme.palette.text.primary,
          fontSize: "3rem",
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
        },
      },
    },
    FeatureListItem: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        adornmentDivider: {
          marginRight: theme.spacing(1),
          borderRight: "1px solid rgba(203, 203, 203, 0.5)",
        },
        adornmentWrapper: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          "& img": {
            objectFit: "contain",
            maxWidth: "60px",
            maxHeight: "55px",
          },
        },
        bottomDivider: {
          borderBottom: "1px solid rgba(203, 203, 203, 0.5)",
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
        },
        listItemText: {
          flex: 2.5,
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(0.5),
          "& .MuiListItemText-primary": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            lineHeight: 1.2,
            fontSize: "1rem",
            fontWeight: 700,
            whiteSpace: "normal",
            wordBreak: "break-all",
          },
          "& .MuiListItemText-secondary": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            lineHeight: 1.4,
          },
        },
        listItemAvatar: {
          "& .MuiAvatar-root": {
            fontSize: "2.275rem",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
        },
        listItemContent: {
          display: "flex",
          width: "100%",
          gap: theme.spacing(2),
        },
        boothNameText: {
          display: "none",
        },
      },
    },
    DirectionSearchContainer: {
      styleOverrides: {
        wrapper: {
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
          },
        },
        suggestionList: {
          marginTop: theme.spacing(8),
          // direction search box = 108 px
          // avoid step button 32 px + top-bottom margin 16px * 2 = 64px
          // top-bottom page layout padding 24px * 2 = 48px
          maxHeight: `calc(100vh - 220px)`,
          overflow: "auto",
          background: theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          position: "absolute",
          zIndex: theme.zIndex.drawer + 1,
          borderRadius: "10px",
          //left-right layout padding = 24 * 2 = 48px
          width: `calc(100vw - 48px)`,
          [theme.breakpoints.up("sm")]: {
            //left-right layout padding = 24 * 2 = 48px
            maxWidth: mixins.mobileDirectionSearch.width - 48,
          },
        },
      },
    },
    AppOrdinalSelector: {
      styleOverrides: {
        root: {
          position: "fixed",
          bottom: theme.spacing(27),
          left: theme.spacing(2),
          borderRadius: `${theme.shape.borderRadius * 1.33}px`,
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[15],
          width: 40,
          padding: theme.spacing(0.25),
        },
        listItemButton: {
          justifyContent: "center",
          borderRadius: 9999,
          backgroundColor: theme.palette.background.default,
          fontSize: "0.875rem",
          fontWeight: theme.typography.fontWeightRegular,
          transition: `color ${theme.transitions.duration.short}ms ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut} , background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
          paddingTop: theme.spacing(0.9375),
          paddingBottom: theme.spacing(0.9375),
          "&.Mui-selected": {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
    VenueSelector: {
      styleOverrides: {
        root: {
          gap: theme.spacing(2),
        },
        buttonWrapper: {
          display: "flex",
          justifyContent: "center",
          transition: `transform ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeIn}`,
          "&.all": { display: "none" },
          "&.selected": {
            transform: "scale(1.77)",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          },
        },
        button: {
          padding: 0,
          backgroundColor: theme.palette.primary.main,
          width: "47px",
          height: "47px",
          borderRadius: "50%",
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            ":hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
        icon: {
          width: "100%",
          height: "100%",
        },
        currentVenueChip: {
          position: "absolute",
          height: "8px",
          width: "32px",
          bottom: "-4px",
        },
      },
    },
    LanguageButton: {
      defaultProps: {
        MenuProps: {
          transformOrigin: { horizontal: "right", vertical: "top" },
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        },
      },
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        menuPaper: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          marginTop: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            marginLeft: -0.5,
            marginRight: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            backgroundColor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
        label: { display: "none" },
        avatar: { width: "32px", height: "32px" },
      },
    },
    OrdinalSelectMenu: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          height: "32px",
          width: "96px",
          textAlign: "center",
          "&.Mui-focused": {
            border: "none",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "& svg": {
              color: theme.palette.primary.contrastText,
            },
          },
        },
        paper: {
          backgroundColor: "transparent",
          boxShadow: "none",
          alignItems: "center",
          maxHeight: "35vh",
          //hide scrollbar
          "::-webkit-scrollbar": {
            display: "none",
          },
          MsOverflowStyle: "none",
          scrollbarWidth: "none",
        },
        menuList: {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        },
        menuItem: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.background.paper,
          borderRadius: "50%",
          height: "40px",
          width: "40px",
          padding: 0,
          minHeight: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transformOrigin: "top center",
          animation: `slideUp 300ms ease-in-out forwards`,
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.contrastText} `,
            color: theme.palette.primary.contrastText,
            ":hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
          ":hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        text: {
          textTransform: "uppercase",
          fontSize: "12px",
          color: theme.palette.text.secondary,
        },
      },
    },
    BorderLinearProgress: {
      styleOverrides: {
        root: {
          position: "relative",
          width: "100%",
          "&:after": {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            content: "''",
            display: "block",
            height: 4,
            width: "100%",
            borderRadius: 5,
            backgroundColor: theme.palette.grey[300],
            right: 0,
          },
        },
        linearProgress: {
          height: 20,
          borderRadius: 15,
          zIndex: 1,
          ":after": {
            position: "absolute",
            zIndex: 5,
            top: "50%",
            transform: "translateY(-50%)",
            height: 4,
            content: "''",
            display: "block",
          },
          "&.MuiLinearProgress-colorPrimary": {
            backgroundColor: "transparent",
            "&.Mui-start": {
              ":after": {
                backgroundColor: theme.palette.primary.main,
              },
            },
            ":after": {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              content: "''",
              display: "inline-block",
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: theme.palette.grey[300],
              zIndex: 1,
              left: 0,
            },
            "&.Mui-complete": {
              ":before": {
                backgroundColor: theme.palette.primary.main,
              },
              ":after": {
                backgroundColor: theme.palette.primary.main,
              },
            },
            ":before": {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              content: "''",
              display: "inline-block",
              width: 14,
              height: 14,
              borderRadius: "50%",
              backgroundColor: theme.palette.grey[300],
              zIndex: 1,
              right: 0,
            },
          },
          "& .MuiLinearProgress-bar": {
            margin: "auto 0",
            height: 4,
            borderRadius: 5,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0px 0px 10px ${theme.palette.primary.main}`,
          },
        },
      },
    },
    StepProgressBar: {
      styleOverrides: {
        wrapper: {
          width: "100%",
          overflowX: "scroll",
          padding: theme.spacing(1),
          marginTop: 0,
          scrollbarWidth: "none", // Hide the scrollbar for firefox
          "&::-webkit-scrollbar": {
            display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
          },
          "&-ms-overflow-style:": {
            display: "none", // Hide the scrollbar for IE
          },
        },
        stepper: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          "& .MuiStepLabel-iconContainer": {
            padding: 0,
          },
        },
        originIcon: {
          "&.MuiSvgIcon-root": {
            height: "28px",
            width: "28px",
            fill: theme.palette.primary.main,
          },
        },
        connector: {
          "& .MuiStepConnector-line": {
            height: 3,
            border: 0,
            minWidth: 36,
            backgroundColor: theme.palette.grey[200],
            borderRadius: 1,
          },
          "&.Mui-active": {
            "& .MuiStepConnector-line": {
              backgroundColor: theme.palette.primary.main,
            },
          },
          "&.Mui-completed": {
            "& .MuiStepConnector-line": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
    VuiDetailCard: {
      styleOverrides: {
        cardHeaderAction: {
          display: "flex",
          columnGap: theme.spacing(1),
        },
        cardHeaderActionButton: {
          borderRadius: "5px",
          padding: theme.spacing(1),
          width: 34,
          height: 34,
        },
        infoListWrapper: {},
      },
    },
    CategoryQuickSearch: {
      styleOverrides: {
        categoryFilterBarContainer: {
          order: 2,
          width: "100vw",
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2.5, 0, 0, 0),
          display: "none",
        },
        scrollPane: {},
        categoryFilterBar: {
          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
            display: "none",
          },
          "& .highlight .MuiSvgIcon-root": {
            display: "block",
            margin: 0,
          },
        },
      },
    },
    VuiSearchPopupContent: {
      styleOverrides: {
        root: {
          order: 1,
        },
      },
    },
    VuiSearchResult: {
      styleOverrides: {
        root: {
          order: 3,
        },
      },
    },
    MapUpdateLoadingScreen: {
      styleOverrides: {
        backdrop: {
          zIndex: theme.zIndex.modal,
        },
        container: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(1),
          color: theme.palette.primary.main,
        },
      },
    },
    VuiNotificationContainer: {
      styleOverrides: {
        root: {},
        snackbarContent: {
          marginTop: theme.spacing(10),
        },
      },
    },
    SuggestionListItem: {
      defaultProps: {},
      styleOverrides: {
        root: {},
      },
    },
    BaseRestrictContentPrompt: {
      styleOverrides: {
        root: {
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          background: `linear-gradient(0deg, ${theme.palette.background.default} 86%, rgba(255, 255, 255, 0) 100%)`,
          height: "60%",
        },
        titleContainer: {
          textAlign: "center",
          marginTop: theme.spacing(1),
        },
        contentContainer: {
          padding: theme.spacing(3),
          display: "flex",
          flexDirection: "column",
        },
        mediaContainer: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: "0.5",
        },
        bodyContainer: {
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(1.5),
          marginTop: theme.spacing(1.5),
          color: theme.palette.text.secondary,
          "&:not(:last-of-type)": {
            marginBottom: theme.spacing(2),
          },
        },
        actionFooterContainer: {
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    RestrictContentPrompt: {
      styleOverrides: {
        icon: {
          color: theme.palette.primary.main,
          width: "80px",
          height: "80px",
        },
        titleText: {
          color: theme.palette.primary.main,
          fontWeight: 700,
        },
        bodyText: {},
        actionButton: {
          width: "80%",
        },
      },
    },
    RestrictContentPermissionPrompt: {
      styleOverrides: {
        icon: {
          color: theme.palette.primary.main,
          width: "80px",
          height: "80px",
        },
        titleText: {
          color: theme.palette.primary.main,
          fontWeight: 700,
        },
        bodyText: {},
        actionButton: {
          width: "80%",
        },
      },
    },
  },
})
