import { useEffect, useState } from "react"

export interface IUseGeolocationPermissionProps {
  enabled?: boolean
}

export interface IUseGeolocationPermissionResult {
  hasPermisison?: boolean
  permission: PermissionState | null
}
export type UseGeolocationPermission = (
  props: IUseGeolocationPermissionProps
) => IUseGeolocationPermissionResult

export const useGeolocationPermission: UseGeolocationPermission = ({
  enabled = false,
} = {}) => {
  const [permission, setPermission] = useState<PermissionState | null>(null)

  useEffect(() => {
    if (!enabled) return
    const checkPermission = async () => {
      try {
        const result = await navigator.permissions.query({
          name: "geolocation",
        })
        setPermission(result.state)

        // Listen for changes in permission status
        result.onchange = () => setPermission(result.state)
      } catch (error) {
        console.error("Failed to check geolocation permission:", error)
      }
    }

    checkPermission()
  }, [enabled])

  return {
    hasPermisison: permission === "granted",
    permission, // 'granted' | 'denied' | 'prompt' | null
  }
}
