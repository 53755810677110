import { useEffect, useCallback, useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"

import config from "../../config"

import {
  fetchAppManifest,
  fetchMapLabels,
  fetchMapDecorations,
} from "../../services/venue"
import { VenueContext } from "./VenueContext"
import { useSearch } from "./hooks/useSearch.ts"
import { useTheme } from "./hooks/useTheme.ts"

import { prepareGeolocationFeature } from "providers/geolocation/utils/prepareGeolocationFeature"
import { prepareMapDecorations } from "./utils/prepareFeature"

import {
  createGeolocationFeature,
  isLatLngQueryString,
} from "utils/geolocation"

import {
  prepareExtensionConfig,
  prepareFeatureQuickSearchOrderConfig,
  prepareMapConfig,
  prepareSearchConfig,
  prepareStepAutoplayConfig,
} from "./utils/prepareConfig"

import { useShortestPath } from "./modules/shortestPath/useShortestPath"
import { RealtimeProvider } from "./modules/realtime"
import {
  useGetImdf,
  useGetEvents,
  useGetPromotions,
  useGetAppConfig,
  useGetSponsoredContents,
} from "./modules/data"
import { REFETCH_INTERVAL } from "constant"

const LOCALE_CONFIG = {
  en: {
    localeKey: "en",
    name: "English",
    flagCode: "gb",
    localizedName: "English",
  },
  th: {
    localeKey: "th",
    name: "Thai",
    flagCode: "th",
    localizedName: "ไทย",
  },
  zh: {
    localeKey: "zh",
    name: "Chinese",
    flagCode: "cn",
    localizedName: "Chinese",
  },
  ja: {
    localeKey: "ja",
    name: "Japanese",
    flagCode: "jp",
    localizedName: "Japanese",
  },
}

const DEFAULT_LOCALE_SUPPORT_LIST = ["en", "th"]

export const defaultAppConfig = {
  map: {},
}

export const VenueProvider = ({ children }) => {
  // Modules
  /** State, Hooks */
  const device = config("device")
  const deviceType = /^kiosk/.test(device) ? "kiosk" : "mobile"
  const isKiosk = /^kiosk/.test(deviceType)

  /** 
   * Example Extension Configs
    {
      "extensions": {
          "app": [
            {
              "to": "promotions", // <-- path for registering route
              "component": "tourist-friendly/pages/promotions" // <-- component path for loading
            },
            ....
          ]
        }
      }
    }
  */

  const { data: imdfData, isFetched, refetch: refetchImdfData } = useGetImdf()
  const { data: events } = useGetEvents({
    needLocation: !isKiosk,
  })
  const { data: promotions } = useGetPromotions({
    needLocation: !isKiosk,
  })

  const { data: appConfig, isFetched: isConfigFetched } = useGetAppConfig()

  const { data: sponsoredContent, isFetched: isSponsoredContentFetched } =
    useGetSponsoredContents({
      appConfig,
      imdfFeatures: imdfData?.features,
      promotions,
      events,
    })

  const mapConfig = useMemo(
    () => prepareMapConfig(_.get(appConfig, "map")),
    [appConfig]
  )

  const stepAutoplayConfig = useMemo(
    () => prepareStepAutoplayConfig(appConfig, deviceType),
    [appConfig, deviceType]
  )

  const searchConfig = useMemo(
    () => prepareSearchConfig(appConfig, deviceType),
    [appConfig, deviceType]
  )

  const extensionConfig = useMemo(
    () => prepareExtensionConfig(appConfig, deviceType),
    [appConfig, deviceType]
  )

  const featureQuickSearchOrderConfig = useMemo(
    () => prepareFeatureQuickSearchOrderConfig(appConfig, deviceType),
    [appConfig, deviceType]
  )

  const { data: labels = [], isFetched: isLabelFetched } = useQuery({
    queryKey: ["labels"],
    queryFn: fetchMapLabels,
    retry: true,
    refetchOnWindowFocus: false,
  })

  const { data: decorations = [], isFetched: isDecorationFetched } = useQuery({
    queryKey: ["elements"],
    queryFn: async () => {
      const decorationsData = await fetchMapDecorations()
      return prepareMapDecorations(decorationsData)
    },
    retry: true,
    refetchOnWindowFocus: false,
  })

  const { data: appManifest } = useQuery({
    queryKey: ["app-manifest"],
    queryFn: fetchAppManifest,
    retry: true,
    refetchOnWindowFocus: false,
  })

  //correct locale properties is supported_locales
  const venueSupportedLocales = useMemo(
    () =>
      _.get(
        _.first(imdfData.venues),
        "properties.supported_locales",
        DEFAULT_LOCALE_SUPPORT_LIST
      ),
    [imdfData.venues]
  )

  const locales = useMemo(() => {
    try {
      return _(venueSupportedLocales)
        .map((locale) => _.get(LOCALE_CONFIG, locale))
        .compact()
        .value()
    } catch (error) {
      return _(DEFAULT_LOCALE_SUPPORT_LIST)
        .map((locale) => _.get(LOCALE_CONFIG, locale))
        .compact()
        .value()
    }
  }, [venueSupportedLocales])

  const themeName = config("themeName")
  const mapThemeName = config("mapThemeName")
  const { themeOptions, initVenueTheme, initMapTheme, mapTheme } = useTheme(
    themeName,
    mapThemeName
  )

  const { search, getSuggestedSearchItems } = useSearch(imdfData.features, {
    config: searchConfig,
    enable: isConfigFetched,
  })

  const {
    findRoute,
    findElevatorPreferredRoute,
    relationshipGraphLoaded,
    transformStepsToGeometries,
  } = useShortestPath(imdfData, {
    /**
     * ==== Example App Config ====
     *    "map": {
     *      ..... other configs .....
     *       "shortest_path": {
     *         "resolution": 0.0016
     *       },
     *     },
     */
    ..._.get(mapConfig, "shortest_path", {}),
    enabled: isFetched,
  })

  const rootCategories = useMemo(
    () =>
      _.uniqBy(
        _.filter(
          imdfData.categories,
          (feature) => feature.properties.parent_id === null
        ),
        "id"
      ),
    [imdfData.categories]
  )

  const findFeatureById = useCallback(
    (id) => imdfData.features.find((f) => f.id === id),
    [imdfData]
  )

  const transformLocationToFeature = useCallback(
    (feature) => {
      return isLatLngQueryString(feature)
        ? prepareGeolocationFeature(
            createGeolocationFeature(feature, { name: "Your Location" }),
            {
              venues: imdfData.venues,
              levels: imdfData.levels,
              units: imdfData.units,
            }
          )
        : findFeatureById(feature) || null
    },
    [findFeatureById, imdfData.venues, imdfData.levels, imdfData.units]
  )

  const filterAmenitiesByCategory = useCallback(
    (category) => {
      return imdfData.amenities.filter(
        (amenityFeature) => amenityFeature.properties.category === category
      )
    },
    [imdfData.amenities]
  )

  const filterOccupantsByRootCategory = useCallback(
    (category) => {
      return imdfData.occupants.filter(
        ({ feature_type, properties }) =>
          feature_type === "occupant" &&
          properties.anchor &&
          (category === undefined ||
            properties.local_parent_categories?.[0]?.id === category)
      )
    },
    [imdfData.occupants]
  )

  const isMultiOrdinalVenue = useMemo(() => {
    return _.uniqBy(imdfData.levels, "properties.ordinal").length > 1
  }, [imdfData.levels])

  const isMultiVenueProject = useMemo(() => {
    return imdfData.venues.length > 1
  }, [imdfData.venues])

  // TODO: Find and Implement default Venue
  const defaultVenue = useMemo(
    () =>
      imdfData.venues.find(({ id }) => id === config("defaultVenueId")) ||
      _.first(imdfData.venues),
    [imdfData]
  )

  const initVenue = useCallback(async () => {
    initVenueTheme()
    initMapTheme()
  }, [initVenueTheme, initMapTheme])

  /**
   * Side Effect when manifest is updated
   */
  useEffect(() => {
    // Set favicon
    const favicon = appManifest?.icons?.find(
      (icon) => icon.type === "image/x-icon"
    )
    if (favicon) {
      document.getElementById("favicon").href = favicon.src
    }
  }, [appManifest])

  // fetch ข้อมูลเริ่มต้นลง feature state และ theme
  useEffect(() => {
    try {
      initVenue()
    } catch (err) {
      throw err
    }
  }, [initVenue])

  const value = {
    ...imdfData,

    // Realtime
    events,
    promotions,

    labels,
    decorations,
    themeOptions,
    appManifest,
    mapTheme,
    locales,
    rootCategories,
    findRoute,
    isMultiOrdinalVenue,
    isMultiVenueProject,
    findElevatorPreferredRoute,
    appConfig,
    stepAutoplayConfig,
    extensionConfig,
    sponsoredContent,
    defaultVenue,
    featureQuickSearchOrderConfig,
    // Flag
    configLoaded: isConfigFetched,
    relationshipGraphLoaded,
    dataLoaded:
      isFetched &&
      isDecorationFetched &&
      isConfigFetched &&
      isSponsoredContentFetched &&
      isLabelFetched,
    mapConfig,
    isKiosk,
    // functions
    refetchImdfData,
    findFeatureById,
    search,
    filterAmenitiesByCategory,
    filterOccupantsByRootCategory,
    transformLocationToFeature,
    transformStepsToGeometries,
    getSuggestedSearchItems,
  }

  return (
    <VenueContext.Provider value={value}>
      <RealtimeProvider>{children}</RealtimeProvider>
    </VenueContext.Provider>
  )
}
