import { ReactElement, useCallback, useMemo } from "react"
import { Button, Typography, useTheme } from "@mui/material"
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded"

import { getIcon } from "utils/icon"
import { useTranslation } from "providers/i18n"
import { BaseRestrictContentPrompt } from "./BaseRestrictContentPrompt"

export const RestrictContentPermissionPrompt = ({ open = false, ...props }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { icon, titleText, bodyText, actionButton } =
    theme.components[`RestrictContentPermissionPrompt`]?.styleOverrides || {}

  const media = useMemo(
    () =>
      getIcon(theme?.assets?.geoGuard?.permission, icon) || (
        <LocationOnRoundedIcon sx={icon} />
      ),
    [icon, theme]
  )

  const handleRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <BaseRestrictContentPrompt
      open={open}
      {...props}
      title={
        <Typography sx={titleText} variant="h6">
          {t("Allow access to your location")}
        </Typography>
      }
      media={media as ReactElement}
      body={
        <>
          <Typography sx={bodyText} variant="body2">
            {t(
              "Enable location services to enhance your app experience and accurate navigation."
            )}
          </Typography>
          <Typography sx={bodyText} variant="body2">
            {t(
              "Open Settings, go to Privacy or App Settings, select the app, choose Location permissions, and set your preferred access level: Never, Ask, While Using, or Always."
            )}
          </Typography>
        </>
      }
      actionFooter={
        <>
          <Button sx={actionButton} variant="contained" onClick={handleRefresh}>
            {t("Refresh")}
          </Button>
        </>
      }
    />
  )
}
