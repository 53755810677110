import axios from "config/connections"
import {
  getCurrentGeolocation,
  IExtendGeolocationPosition,
} from "./geolocation"

export const attachLocationHeader = async () => {
  try {
    /**
     *  Retrieve user location from their device.
     *  Attach coordinates to the "X-Venue-User-Location" header in the Axios instance.
     */
    const { coordinates } =
      (await getCurrentGeolocation()) as IExtendGeolocationPosition
    axios.defaults.headers.common["X-Venue-User-Location"] =
      JSON.stringify(coordinates)
  } catch (err) {}
}
