import { useCallback } from "react"
import { useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"

import { useGetPromotions } from "./useGetPromotions"
import { useGetImdf } from "../useGetImdf"
import { preparePromotion } from "./utils/preparePromotion"

import { Promotion, PromotionData } from "../../../types"

export interface IUseGetPromotionOptions {
  refetchInterval?: number | false
}

export type UseGetPromotion = (
  id: string,
  options?: IUseGetPromotionOptions
) => UseQueryResult<PromotionData, unknown>

export const useGetPromotion: UseGetPromotion = (id, options = {}) => {
  const { refetchInterval = false } = options
  const queryClient = useQueryClient()
  const { data: imdfData } = useGetImdf()
  const { isFetched } = useGetPromotions({ refetchInterval })
  const findOne = useCallback(
    (id) => imdfData.features.find((f) => f.id === id),
    [imdfData]
  )
  return useQuery<Promotion | undefined, unknown, PromotionData>({
    queryKey: ["promotions", id],
    queryFn: async () => {
      const promotions: Promotion[] =
        queryClient.getQueryData(["promotions"]) || []
      const promotion = promotions.find((event) => event.id === id)
      return Promise.resolve(promotion)
    },
    select: (data: PromotionData): PromotionData =>
      preparePromotion(data, findOne),
    staleTime: 0,
    enabled: isFetched,
    refetchOnWindowFocus: false,
    refetchInterval,
  })
}
