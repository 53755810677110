import _, { isNil } from "lodash"
import { get } from "lodash"
import { getIconPathString } from "../components/icons/pathString"
import { IFeature } from "interfaces"
import { isValidTime } from "./time"
import dayjs from "config/dayjs"
import { Dayjs } from "dayjs"

// CUSTOM INTERFACE/TYPE
interface IMultiLanguageInfo {
  en?: string
  th?: string
  zh?: string
}
interface IFeatureDetail {
  id: number | string
  group?: object | string | string[] // Inferred types from the use of i18n's t function
  name: IMultiLanguageInfo | string
  category?: IMultiLanguageInfo[] | string | string[] | null
  level?: object | string // Inferred types from usage below
  locationDescription?: string | null
  zone?: object | string // Inferred types from usage below
  isAd?: boolean
  boothName?: string
}
type Location = null | IMultiLanguageInfo | string

// FUNCTION RETURN INTERFACE
interface IAmenityDetail extends IFeatureDetail {
  description: IMultiLanguageInfo
  location: Location
  venueLogoSimple?: string
  venueLogo?: string
  venueRef: string
}

interface IUnitDetail extends IFeatureDetail {
  location: Location
}

interface IEventDetail extends IFeatureDetail {
  description: IMultiLanguageInfo
  coverImage: string
  location: Location
  gallery: { [key: string]: unknown }[]
  startTime: Dayjs
  endTime: Dayjs
  startDate: Dayjs
  endDate: Dayjs
  locationId: string
  hasLocation: boolean
  websiteLink: string
  venueLogo: string
  locationAccessRequired?: boolean
  isFullContent?: boolean
}

interface IPromotionDetail extends IFeatureDetail {
  description: IMultiLanguageInfo
  coverImage: string
  location: Location
  gallery: { [key: string]: unknown }[]
  startTime: Dayjs
  endTime: Dayjs
  startDate: Dayjs
  endDate: Dayjs
  locationId: string
  hasLocation: boolean
  websiteLink: string
  venueLogo: string
  locationAccessRequired?: boolean
  isFullContent?: boolean
}

interface IKioskDetail extends IFeatureDetail {
  location: Location
}

interface IVenueDetail extends IFeatureDetail {
  logoSimple: string
  logo: string
  reference: string
}

export interface IOccupantDetail extends IFeatureDetail {
  logo: string
  extendedLogo: string
  featuredImage: string
  coverImage: string
  venueLogoSimple: string
  venueLogo: string
  venueRef: string
  phone: string
  hours: string
  websiteLink: string
  gallery: { [key: string]: unknown }[]
  privileges: IFeature[]
  description: IMultiLanguageInfo | string
  location: Location
  isFeatured: boolean
  categoryIconPath: string
}

interface IGeolocationDetail extends IFeatureDetail {
  venue: IFeature
}

interface ILevelDetail extends IFeatureDetail {
  ordinal: number
}

interface ITaxonomyDetail extends IFeatureDetail {}

interface IFeaturePropsParsed {
  id: string
  avatarProps: {
    src: string
    path: string
  }
  primary?: string
  secondary?: string
  [key: string]: unknown
}

// FUNCTION TYPE DEFINITION
// GetFeatureLevel
type ParseLevelText = (feature: IFeature, lang?: string) => string

type GetFeatureId = (
  feature: IFeature,
  defaultValue?: string | number
) => string | number

type GetFeatureName = (
  feature: IFeature,
  defaultValue?: string
) => IMultiLanguageInfo | string

type GetFeatureDescription = (
  feature: IFeature,
  defaultValue?: string
) => IMultiLanguageInfo | string

type GetCategoryName = (feature: IFeature) => string | string[]

type GetAmenityDetail = (feature: IFeature) => IAmenityDetail

type GetUnitDetail = (feature: IFeature) => IUnitDetail

type GetEventDetail = (feature: IFeature) => IEventDetail

type GetPromotionDetail = (feature: IFeature) => IPromotionDetail

type GetKioskDetail = (feature: IFeature) => IKioskDetail

type GetVenueDetail = (venue: IFeature) => IVenueDetail

type GetOccupantDetail = (occupant: IFeature) => IOccupantDetail

type GetGeolocationDetail = (geolocation: IFeature) => IGeolocationDetail

type GetLevelDetail = (level: IFeature) => ILevelDetail

type GetTaxonomyDetail = (taxonomy: IFeature) => ITaxonomyDetail

type GetLocationNameFromFeature = (feature: IFeature) => Location

type GetFeatureDetail = (
  feature: IFeature
) =>
  | IAmenityDetail
  | IEventDetail
  | IUnitDetail
  | IKioskDetail
  | IOccupantDetail
  | IPromotionDetail
  | ILevelDetail
  | ITaxonomyDetail
  | IVenueDetail
  | IGeolocationDetail
  | null

type ListItemPropsParserFactoryParams = ({
  t,
  hideLevel,
  hideVenueLogo,
}: {
  t: any
  hideLevel: boolean
  hideVenueLogo: boolean
}) => ListItemPropsParserFactory
type ListItemPropsParserFactory = (feature: IFeature) => IFeaturePropsParsed

interface SortingConfigFeatureByName {
  field: string
  order: "asc" | "desc"
}
type CreateSortingConfigFeatureByName = (
  chosenLanguage: string
) => SortingConfigFeatureByName[]

export const parseLevelText: ParseLevelText = (feature, lang = "en") => {
  if (!feature?.properties?.level && !feature?.properties?.level_name) return ""

  const { level, level_name } = feature.properties
  const levelName = level?.properties?.name.en || level_name

  return levelName
}

export const getFeatureId: GetFeatureId = (feature, defaultValue = "") =>
  get(feature, "id", defaultValue)

export const getFeatureName: GetFeatureName = (feature, defaultValue = "") => {
  return get(feature, "properties.name") || defaultValue
}

export const getFeatureDescription: GetFeatureDescription = (
  feature,
  defaultValue = ""
) => get(feature, "properties.description", defaultValue)

export const getCategoryName: GetCategoryName = (feature) => {
  const { properties, feature_type } = feature
  switch (feature_type) {
    case "amenity": {
      return `${feature_type}.${properties.category}`
    }
    case "occupant": {
      const localParentCategory = get(
        feature,
        "properties.local_parent_categories"
      )
      return localParentCategory.map((category: IFeature) =>
        get(category, "properties.name", "")
      )
    }
    default:
      return ""
  }
}

const getAmenityDetail: GetAmenityDetail = (feature) => {
  const { feature_type, properties } = feature
  const {
    units,
    category,
    venue,
    description,
    isAd = false,
  } = feature?.properties || {}
  const gallery = get(properties, "gallery")
  const categoryName = `${feature_type}.${category}`
  const levelName = get(units, "[0].properties.level.properties.name.en")
  const nameObject = getFeatureName(feature)
  const nameText = _.isEmpty(nameObject) ? categoryName : nameObject
  const zoneName = get(units, "[0].properties.section.properties.name", "")
  let locationDescription = get(feature, "properties.location_description", "")
  locationDescription = !_.isEmpty(locationDescription)
    ? locationDescription
    : null

  const locationName = getLocationNameFromFeature(feature)

  const venueProperties = get(venue, "properties")
  const venueLogoSimple =
    venueProperties?.logo_simple?.formats?.thumbnail?.url ||
    venueProperties?.logo_simple?.url
  const venueLogo =
    venueProperties?.logo?.formats?.thumbnail?.url || venueProperties?.logo?.url
  const venueRef = venueProperties.reference

  return {
    id: getFeatureId(feature),
    name: nameText,
    level: levelName,
    zone: zoneName,
    category: categoryName,
    location: locationName,
    description,
    locationDescription: locationDescription,
    venueLogoSimple,
    venueLogo,
    venueRef,
    gallery,
    isAd,
  }
}

const getUnitDetail: GetUnitDetail = (feature) => {
  const { name, level, section } = feature?.properties || {}
  const levelName = getFeatureName(level)
  const zoneName = getFeatureName(section)
  const locationName = getLocationNameFromFeature(feature)

  return {
    name,
    level: levelName,
    zone: zoneName,
    id: getFeatureId(feature),
    location: locationName,
  }
}
export const getEventDetail: GetEventDetail = (feature) => {
  const {
    name,
    description,
    cover_image,
    gallery,
    website_link: websiteLink,
    feature_id: locationId,
    feature: locationFeature,
    start_date,
    end_date,
    daily_start_time,
    daily_end_time,
    venue,
    isAd = false,
    location_access_required: locationAccessRequired = false,
    is_full_content: isFullContent = true,
  } = feature?.properties || {}
  const venueProperties = get(venue, "properties")
  const locationName = getLocationNameFromFeature(feature)
  const coverImage = cover_image?.url
  const levelName = parseLevelText(feature.properties.feature)
  const startTime = isValidTime(daily_start_time)
    ? daily_start_time.slice(0, 5)
    : null
  const endTime = isValidTime(daily_end_time)
    ? daily_end_time.slice(0, 5)
    : null
  const startDate = dayjs(start_date)
  const endDate = dayjs(end_date)
  const venueLogo =
    venueProperties?.logo?.formats?.thumbnail?.url || venueProperties?.logo?.url
  const boothName =
    get(feature, "properties.feature.properties.kiosk.properties.name") ||
    get(feature, "properties.feature.properties.unit.properties.name") ||
    " "

  // cover_image
  return {
    name,
    description,
    coverImage,
    id: getFeatureId(feature),
    location: locationName,
    gallery,
    level: levelName,
    websiteLink,
    locationId,
    hasLocation: !!locationFeature,
    startDate,
    endDate,
    startTime,
    endTime,
    venueLogo,
    isAd,
    boothName,
    locationAccessRequired,
    isFullContent,
  }
}

export const getPromotionDetail: GetPromotionDetail = (feature) => {
  const {
    name,
    description,
    cover_image,
    gallery,
    website_link: websiteLink,
    feature_id: locationId,
    feature: locationFeature,
    start_date,
    end_date,
    daily_start_time,
    daily_end_time,
    venue,
    isAd = false,
    location_access_required: locationAccessRequired = false,
    is_full_content: isFullContent = true,
  } = feature?.properties || {}
  const venueProperties = get(venue, "properties")

  const locationName = getLocationNameFromFeature(feature)
  const coverImage = cover_image?.url
  const levelName = parseLevelText(feature.properties.feature)
  const startTime = isValidTime(daily_start_time)
    ? daily_start_time.slice(0, 5)
    : null
  const endTime = isValidTime(daily_end_time)
    ? daily_end_time.slice(0, 5)
    : null
  const startDate = dayjs(start_date)
  const endDate = dayjs(end_date)
  const venueLogo =
    venueProperties?.logo?.formats?.thumbnail?.url || venueProperties?.logo?.url
  const boothName =
    get(feature, "properties.feature.properties.kiosk.properties.name") ||
    get(feature, "properties.feature.properties.unit.properties.name") ||
    " "

  return {
    name,
    description,
    coverImage,
    id: getFeatureId(feature),
    location: locationName,
    gallery,
    level: levelName,
    websiteLink,
    locationId,
    hasLocation: !!locationFeature,
    startDate,
    endDate,
    startTime,
    endTime,
    venueLogo,
    isAd,
    boothName,
    locationAccessRequired,
    isFullContent,
  }
}

const getKioskDetail: GetKioskDetail = (feature) => {
  const { name, level, section } = feature?.properties || {}
  const zoneName = get(section, "properties.name") || ""
  const levelName = getFeatureName(level)
  const locationName = getLocationNameFromFeature(feature)

  return {
    name,
    zone: zoneName,
    level: levelName,
    id: getFeatureId(feature),
    location: locationName,
  }
}

export const getVenueDetail: GetVenueDetail = (venue) => {
  const {
    name,
    logo: logoField,
    logo_simple: logoSimpleField,
    reference,
  } = venue?.properties || {}

  const logoSimple =
    logoSimpleField?.formats?.thumbnail?.url || logoSimpleField?.url
  const logo = logoField?.formats?.thumbnail?.url || logoField?.url

  return {
    id: getFeatureId(venue),
    name,
    logoSimple,
    logo,
    reference,
  }
}

export const getOccupantDetail: GetOccupantDetail = (occupant) => {
  const { properties } = occupant
  const {
    name,
    local_parent_categories,
    logo,
    extended_logo,
    featured_image,
    cover_image,
    level_name,
    anchor,
    hours,
    phone,
    groups,
    venue,
    is_featured,
    is_highlight,
    website_link: websiteLink,
    isAd = false,
  } = occupant?.properties || {}
  const venueProperties = get(venue, "properties")
  const categoryIconPath = local_parent_categories?.[0]?.properties?.icon_path
  const categoryName = local_parent_categories?.[0]?.properties.name
  const groupName = groups?.[0]?.properties.name
  const zoneName = get(anchor, "properties.section.properties.name") || ""
  const logoImage = logo?.formats?.thumbnail?.url || logo?.url
  const extendedLogoImage =
    extended_logo?.formats?.large?.url || extended_logo?.url
  const featuredImage =
    featured_image?.formats?.thumbnail?.url || featured_image?.url
  const coverImage = cover_image?.formats?.large?.url || cover_image?.url
  const venueLogoSimple =
    venueProperties?.logo_simple?.formats?.thumbnail?.url ||
    venueProperties?.logo_simple?.url
  const venueLogo =
    venueProperties?.logo?.formats?.thumbnail?.url || venueProperties?.logo?.url
  const venueRef = venueProperties.reference
  const gallery = get(properties, "gallery")
  const privileges = get(properties, "privileges")
  const description = getFeatureDescription(occupant)
  const locationName = getLocationNameFromFeature(occupant)
  const isFeatured = is_featured
  const isHighlight = is_highlight // Used for magnet shop case.
  const boothName =
    get(properties, "kiosk.properties.name") ||
    get(properties, "unit.properties.name")
  return {
    id: getFeatureId(occupant),
    zone: zoneName,
    logo: logoImage,
    extendedLogo: extendedLogoImage,
    featuredImage,
    coverImage,
    category: categoryName,
    categoryIconPath,
    venueLogoSimple,
    venueLogo,
    venueRef,
    phone,
    level: level_name,
    name,
    hours,
    websiteLink,
    gallery,
    privileges,
    group: groupName,
    description: description,
    location: locationName,
    isFeatured,
    isHighlight, // Used for magnet shop case.
    boothName,
    isAd,
  }
}
const getGeolocationDetail: GetGeolocationDetail = (geolocation) => {
  const { name, level_name, venue } = geolocation?.properties || {}
  return {
    id: getFeatureId(geolocation),
    name,
    level: level_name,
    venue,
  }
}

const getLevelDetail: GetLevelDetail = (level) => {
  const { properties } = level
  const { name, ordinal } = properties || {}
  return {
    id: getFeatureId(level),
    name,
    ordinal,
  }
}

export const getTaxonomyDetail: GetTaxonomyDetail = (taxonomy) => {
  const { properties } = taxonomy
  const { name } = properties || {}
  return { id: getFeatureId(taxonomy), name }
}

export const getLocationNameFromFeature: GetLocationNameFromFeature = (
  feature
) => {
  const { feature_type } = feature || {}
  const properties = get(feature, "properties")

  switch (feature_type) {
    case "unit":
      return getFeatureName(get(properties, "section"))
    case "kiosk":
      return getFeatureName(
        get(properties, "anchor.properties.unit.properties.section")
      )
    case "amenity":
      return getFeatureName(get(properties, "units[0]"))
    case "occupant":
      const kiosk = get(properties, "kiosk")
      const unit = get(properties, "anchor.properties.unit")
      return getFeatureName(kiosk) || getFeatureName(unit)
    case "event":
    case "promotion":
      const feature = get(properties, "feature")
      if (isNil(feature)) return ""
      const { feature_type, properties: featureProperties } = feature
      const defaultValue = `${feature_type}.${featureProperties?.category}`
      return getFeatureName(feature, defaultValue)

    case "level":
      const levelFeature = get(properties, "feature")
      return getFeatureName(levelFeature)
    default:
      return null
  }
}

export const getFeatureDetail: GetFeatureDetail = (feature) => {
  const { feature_type } = feature || {}
  switch (feature_type) {
    case "amenity":
      return getAmenityDetail(feature)
    case "unit":
      return getUnitDetail(feature)
    case "kiosk":
      return getKioskDetail(feature)
    case "occupant":
      return getOccupantDetail(feature)
    case "event":
      return getEventDetail(feature)
    case "promotion":
      return getPromotionDetail(feature)
    case "level":
      return getLevelDetail(feature)
    case "taxonomy":
      return getTaxonomyDetail(feature)
    case "venue":
      return getVenueDetail(feature)
    case "geolocation":
      return getGeolocationDetail(feature)
    default:
      return null
  }
}

/** Create ListItem Props  */
export const listItemPropsParserFactory: ListItemPropsParserFactoryParams =
  ({ t, hideLevel, hideVenueLogo }): ListItemPropsParserFactory =>
  (feature) => {
    const { feature_type, id, properties } = feature
    switch (feature_type) {
      case "amenity": {
        const {
          group,
          name,
          venueRef,
          /* category,  */ level,
          locationDescription,
          zone,
          isAd = false,
        } = getFeatureDetail(feature) as IAmenityDetail
        /* const categoryText = t(category) */
        const floorText =
          !hideLevel && `${t("Level Text", { level: t(level) })}`
        const zoneText = zone && `${t("Zone")} ${t(zone)}`

        const locationDescriptionText =
          locationDescription && t(locationDescription)
        const primary =
          // The name can be either an IMDF category name ("restroom.female") or a custom name ("Toilet A").
          t(name)
        const groupText = group && t(group)
        const secondary = locationDescriptionText
          ? _.compact([floorText, locationDescriptionText]).join(" - ") // Floor GF - Next to McDonald
          : _.compact([
              _.compact([floorText, groupText]).join(" - "),
              zoneText,
            ]).join(" | ")
        const avatarSrc =
          properties?.logo?.formats?.thumbnail?.url || properties?.logo?.url
        const categoryIconPath = getIconPathString(
          `${feature_type}.${properties.category}`
        )
        const venueLogo =
          !hideVenueLogo && properties?.venue?.properties?.logo?.url

        return {
          id,
          avatarProps: {
            src: avatarSrc,
            path: categoryIconPath,
          },
          venueLogo,
          venueRef,
          primary,
          secondary,
          name,
          isAd,
          floorText,
          zoneText,
          groupText,
          ...feature,
        }
      }
      case "occupant":
      default: {
        const {
          group,
          name,
          category,
          level,
          zone,
          categoryIconPath,
          isAd = false,
          boothName,
          venueRef,
        } = getFeatureDetail(feature) as IOccupantDetail

        const categoryText = category && t(category)
        const floorText =
          !hideLevel && `${t("Level Text", { level: t(level) })}`
        const zoneText = zone && `${t("Zone")} ${t(zone)}`
        const boothNameText = boothName && `${t("Booth")} ${t(boothName)} `
        const groupText = group && t(group)

        const avatarSrc =
          properties?.logo?.formats?.thumbnail?.url || properties?.logo?.url
        const venueLogo =
          !hideVenueLogo && properties?.venue?.properties?.logo?.url

        return {
          id,
          avatarProps: {
            src: avatarSrc,
            path: categoryIconPath,
          },
          venueRef,
          venueLogo,
          name,
          isAd,
          categoryText,
          floorText,
          zoneText,
          boothNameText,
          groupText,
          ...feature,
        }
      }
    }
  }

// Create Occupant Name Sort By Language
export const createSortingConfigFeatureByName: CreateSortingConfigFeatureByName =
  (chosenLanguage) => {
    return [
      {
        field: `properties.name.${chosenLanguage}`,
        order: "asc",
      },
      {
        field: `properties.name.en`,
        order: "asc",
      },
    ]
  }

// Create a featured occupant Sorting config by language and order
export const createSortingFeaturedOccupantConfig: CreateSortingConfigFeatureByName =
  (chosenLanguage) => {
    return [
      {
        field: `properties.order`,
        order: "asc",
      },
      ...createSortingConfigFeatureByName(chosenLanguage),
    ]
  }
//filter upcoming and current event/promotion
export const filterCurrentFeature = (feature) => {
  const currentTime = dayjs()
  const { properties } = feature
  const startDate = dayjs(properties?.start_date)
  const endDate = dayjs(properties?.end_date)

  return currentTime.isAfter(startDate) && currentTime.isBefore(endDate)
}
export const filterUpcomingFeature = (feature) => {
  const currentTime = dayjs()
  const { properties } = feature
  const startDate = dayjs(properties?.start_date)
  return currentTime.isBefore(startDate)
}
