import { useCallback } from "react"
import { useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"

import { useGetImdf } from "../useGetImdf"
import { useGetEvents } from "./useGetEvents"
import { Event, EventData } from "../../../types"
import { prepareEvent } from "./utils/prepareEvent"

export interface IUseGetEventsOptions {
  refetchInterval?: number | false
}

export type UseGetEvent = (
  id: string,
  options?: IUseGetEventsOptions
) => UseQueryResult<EventData, unknown>

export const useGetEvent: UseGetEvent = (id, options = {}) => {
  const { refetchInterval = false } = options
  const queryClient = useQueryClient()
  const { data: imdfData } = useGetImdf()
  const { isFetched } = useGetEvents({ refetchInterval })
  const findOne = useCallback(
    (id) => imdfData.features.find((f) => f.id === id),
    [imdfData]
  )
  return useQuery<Event | undefined, unknown, EventData>({
    queryKey: ["events", id],
    queryFn: async () => {
      const events: Event[] = queryClient.getQueryData(["events"]) || []
      const event = events.find((event) => event.id === id)
      return Promise.resolve(event)
    },
    select: (event: Event): EventData => prepareEvent(event, findOne),
    staleTime: 0,
    enabled: isFetched,
    refetchInterval,
  })
}
