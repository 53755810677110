import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { useGetImdf } from "../useGetImdf"
import { Event, EventData } from "../../../types"
import { prepareEvent } from "./utils/prepareEvent"
import { fetchGeojson } from "../../../utils/fetchUtils"
import { useCallback } from "react"
import { attachLocationHeader } from "utils/location-header"

export interface IUseGetEventsProps {
  refetchInterval?: number | false
  needLocation?: boolean
}

export type UseGetEvents = (
  props?: IUseGetEventsProps
) => UseQueryResult<EventData[], unknown>

export const useGetEvents: UseGetEvents = ({
  refetchInterval,
  needLocation = true,
} = {}) => {
  const { data: imdfData } = useGetImdf()
  const findOne = useCallback(
    (id) => imdfData.features.find((f) => f.id === id),
    [imdfData]
  )
  return useQuery<Event[], unknown, EventData[]>({
    queryKey: ["events"],
    queryFn: async () => {
      if (needLocation) await attachLocationHeader()
      const response = await fetchGeojson<Event>("event")
      return response.data
    },
    select: (events: Event[]): EventData[] =>
      events.map((event) => prepareEvent(event, findOne)),
    placeholderData: [],
    retry: true,
    refetchOnWindowFocus: false,
    refetchInterval,
  })
}
