import { useCallback } from "react"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { useGetImdf } from "../useGetImdf"
import { fetchGeojson } from "../../../utils/fetchUtils"
import { preparePromotion } from "./utils/preparePromotion"
import { Promotion, PromotionData } from "../../../types"
import { attachLocationHeader } from "utils/location-header"
export interface IUseGetPromotionsProps {
  refetchInterval?: number | false
  needLocation?: boolean
}

export type UseGetPromotions = (
  props?: IUseGetPromotionsProps
) => UseQueryResult<PromotionData[], unknown>

export const useGetPromotions: UseGetPromotions = ({
  refetchInterval,
  needLocation = true,
} = {}) => {
  const { data: imdfData } = useGetImdf()
  const findOne = useCallback(
    (id) => imdfData.features.find((f) => f.id === id),
    [imdfData]
  )
  return useQuery<Promotion[], unknown, PromotionData[]>({
    queryKey: ["promotions"],
    queryFn: async () => {
      if (needLocation) await attachLocationHeader()
      const response = await fetchGeojson<Promotion>("promotion")
      return response.data
    },
    placeholderData: [],
    refetchOnWindowFocus: false,
    retry: true,
    select: (data: Promotion[]): PromotionData[] =>
      data.map((promotion) => preparePromotion(promotion, findOne)),
    refetchInterval,
  })
}
