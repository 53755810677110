import React, { ReactElement } from "react"
import { Box, SxProps, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"
import { MotionDiv, showContent } from "components/motions"

export interface IBaseRestrictContentPromptProps {
  title?: ReactElement | string
  media?: ReactElement
  actionFooter?: ReactElement
  body?: ReactElement | string
  sx?: SxProps
  open?: boolean
}

export const BaseRestrictContentPrompt: React.FC<
  IBaseRestrictContentPromptProps
> = ({ sx = {}, open = false, media, title, body, actionFooter, ...props }) => {
  const theme = useTheme()
  const {
    root,
    titleContainer,
    contentContainer,
    mediaContainer,
    bodyContainer,
    actionFooterContainer,
  } = theme.components?.[`BaseRestrictContentPrompt`]?.styleOverrides || {}

  return (
    open && (
      <MotionDiv
        animate="animate"
        initial="initial"
        variants={showContent}
        sx={deepmerge(root, sx)}
      >
        <Box sx={contentContainer}>
          {media && <Box sx={mediaContainer}>{media}</Box>}
          {title && <Box sx={titleContainer}>{title}</Box>}
          {body && <Box sx={bodyContainer}>{body}</Box>}
          {actionFooter && <Box sx={actionFooterContainer}>{actionFooter}</Box>}
        </Box>
      </MotionDiv>
    )
  )
}
